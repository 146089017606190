#install-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
  }

  #install-popup .popup-content {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    flex-direction: column;
    text-align: center;
    width: 310px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  }

  #install-popup.active {
    display: block;
  }

  #install-popup.active~#root>div>div>div>div>div>div>div {
    filter: blur(3px);
  }

  #install-popup .popup-content .close-btn {
    color: white;
    width: 36px;
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    right: 2px;
    top: -40px;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  }

  #install-popup .popup-content img.install-banner {
    width: 100%;
    margin-top: 30px;
  }

  #install-popup .popup-content h2 {
    color: #3F3F3F;
    margin-bottom: 0;
  }

  #install-popup .popup-content p {
    padding: 0 20px 10px 20px;
  }

  #install-popup .popup-content .instructions {
    background-color: #f7f7f7;
    border-radius: 0 0 10px 10px;
    padding: 16px 10px;
    font-size: 14px;
  }

  #install-popup .popup-content .instructions img {
    vertical-align: -35%;
    margin: 0 2px;
    height: 24px;
  }